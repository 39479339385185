@charset "UTF-8";
@media only print, only screen and (min-width: 30.063em) {
  /* for small screens */ }

@media only print, only screen and (min-width: 40.063em) {
  /* for medium screens */ }

@media only print, only screen and (min-width: 60.063em) {
  /* for large screens */ }

* {
  margin: 0;
  padding: 0; }

img {
  -ms-interpolation-mode: bicubic; }

a,
a:visited {
  color: #da0e73;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s; }

a:hover {
  color: #e13e8f; }

a img {
  border: 0; }

body {
  font-family: "Open Sans", "sans-serif"; }

.printonly {
  display: none; }

.nowrap {
  white-space: nowrap; }

.tab-bar .secu-icon {
  position: relative;
  display: block;
  height: 2.8125rem;
  width: 2.8125rem;
  padding: 0; }

.tab-bar .secu-icon span {
  display: block;
  height: 2.8125rem;
  width: 2.8125rem;
  text-indent: 2.8125rem;
  overflow: hidden;
  background-image: url("../images/icon-secu.png");
  background-position: center center;
  background-repeat: no-repeat; }

.tab-bar .panier-icon span {
  display: block;
  height: 2.8125rem;
  width: 2.8125rem;
  text-indent: 2.8125rem;
  overflow: hidden;
  background-image: url("../images/icon-panier@2x.png");
  background-position: center center;
  background-repeat: no-repeat;
  -moz-background-size: 24px 24px;
  -o-background-size: 24px 24px;
  -webkit-background-size: 24px 24px;
  background-size: 24px 24px; }

.main-section {
  background: #EEE;
  /* Old browsers */
  background: -moz-linear-gradient(top, #DDD 0%, #EEE 320px);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #DDD 0%, #EEE 320px);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #DDD 0%, #EEE 320px);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: center top;
  background-repeat: no-repeat;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  /*min-height: 480px;*/
  /*overflow-y: auto;*/ }

.tab-bar-panier {
  position: absolute;
  top: 0;
  right: 2.8125rem;
  height: 2.8125rem;
  width: 2.8125rem;
  border-left: 1px solid #444444;
  box-shadow: -1px 0 0 #1a1a1a; }

.banner {
  background-color: #2d2d2d; }

.banner .secu-menu {
  position: relative;
  float: right;
  margin-left: 1rem;
  margin-right: 1rem; }

.banner .secu-menu > label {
  position: relative;
  display: block;
  color: #EEE;
  min-width: 170px;
  height: 3rem;
  line-height: 2.8rem;
  padding: 0 0.5rem;
  font-size: 0.9em;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase; }

.banner .secu-menu:hover > label {
  color: #FFF;
  background-color: #444; }

.no-touch .banner .secu-menu > label {
  height: 2rem;
  line-height: 1.9rem; }

.banner .secu-menu > label:before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: -1px;
  vertical-align: text-bottom;
  background-image: url("../images/icon-secu2.png");
  background-position: center center;
  background-repeat: no-repeat; }

.banner .secu-menu > label:after {
  content: "";
  position: absolute;
  height: 0.125rem;
  width: 0;
  bottom: 0;
  left: 50%;
  background-color: #CCC;
  -webkit-transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  -moz-transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  -o-transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s; }

.banner .secu-menu:hover > label::after {
  left: 5%;
  width: 90%; }

.banner .secu-menu > ul {
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  max-height: 0;
  width: 100%;
  background-color: #444;
  -webkit-transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -moz-transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -o-transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }

.banner .secu-menu:hover > ul {
  max-height: 20rem;
  -webkit-transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -moz-transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -o-transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s; }

.banner .secu-menu > ul > li {
  display: block;
  position: relative; }

.banner .secu-menu > ul > li > .bt {
  display: block;
  line-height: 1.2em;
  padding: 1em;
  text-align: center;
  color: #EEE;
  font-size: 0.9em; }

.no-touch .banner .secu-menu > ul > li > .bt {
  padding: 0.5em 1em; }

.banner .secu-menu > ul > li > .bt:hover {
  background-color: rgba(255, 255, 255, 0.15); }

.banner .secu-menu > ul > li.logout .bt:before {
  content: "";
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.25rem;
  margin-bottom: -1px;
  vertical-align: text-bottom;
  background-image: url("../images/icon-logout.png");
  background-position: center center;
  background-repeat: no-repeat; }

.banner .panier-menu {
  position: relative;
  float: right;
  margin-left: 1rem;
  margin-right: 1rem; }

.banner .panier-menu > label {
  position: relative;
  display: block;
  color: #EEE;
  min-width: 170px;
  height: 3rem;
  line-height: 2.8rem;
  padding: 0 0.5rem;
  font-size: 0.9em;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase; }

.banner .panier-menu:hover > label {
  color: #FFF;
  background-color: #444; }

.no-touch .banner .panier-menu > label {
  height: 2rem;
  line-height: 1.9rem; }

.banner .panier-menu > label:before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: -1px;
  vertical-align: text-bottom;
  background-image: url("../images/icon-panier.png");
  background-position: center center;
  background-repeat: no-repeat; }

.banner .panier-menu > label:after {
  content: "";
  position: absolute;
  height: 0.125rem;
  width: 0;
  bottom: 0;
  left: 50%;
  background-color: #CCC;
  -webkit-transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  -moz-transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  -o-transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  transition: left 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s, width 0.25s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s; }

.banner .panier-menu:hover > label::after {
  left: 5%;
  width: 90%; }

.banner .panier-menu > ul {
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  max-height: 0;
  width: 100%;
  background-color: #444;
  -webkit-transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -moz-transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -o-transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: max-height 0.25s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }

.banner .panier-menu:hover > ul {
  max-height: 20rem;
  -webkit-transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -moz-transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -o-transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: max-height 0.35s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s; }

.banner .panier-menu > ul > li {
  display: block;
  position: relative; }

.banner .panier-menu > ul > li > .bt {
  display: block;
  line-height: 1.2em;
  padding: 1em;
  text-align: center;
  color: #EEE;
  font-size: 0.9em; }

.no-touch .banner .panier-menu > ul > li > .bt {
  padding: 0.5em 1em; }

.banner .panier-menu > ul > li > .bt:hover {
  background-color: rgba(255, 255, 255, 0.15); }

.banner .panier-menu > ul > li.logout .bt:before {
  content: "";
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.25rem;
  margin-bottom: -1px;
  vertical-align: text-bottom;
  background-image: url("../images/icon-logout.png");
  background-position: center center;
  background-repeat: no-repeat; }

.header .bt_accueil {
  display: block;
  text-indent: -9999px;
  width: 100%;
  height: 194px;
  background-image: url("../images/le-comptoir-du-jardinier.png");
  background-repeat: no-repeat;
  background-position: center top;
  outline: none;
  margin-top: 16px; }

/*////////////////////////////////////////////////////////////////////////////*/
/*///////////////////////////////// RECHERCHE ////////////////////////////////*/
/*////////////////////////////////////////////////////////////////////////////*/
.menuRecherche form {
  padding: 0;
  margin: 0; }

.menuRecherche input {
  padding: 0;
  margin: 0;
  height: 32px;
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 1px #3e3b36;
  float: left; }

.menuRecherche input[type=text] {
  width: 185px;
  padding: 0 8px; }

.menuRecherche input[type=submit] {
  width: 37px;
  text-indent: -9999px;
  background-image: url("../images/bt_search.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 1px #3e3b36;
  border-left: 0; }

.menuRecherche input[type=submit]:hover {
  background-color: white; }

.tab-bar .menuRecherche input {
  background-color: #DDD; }

.tab-bar .menuRecherche {
  width: 197px;
  margin: 0 auto;
  padding-top: 6px;
  padding-right: 2.8125rem; }

.tab-bar .menuRecherche input[type=text] {
  width: 115px;
  /* 160px-2.8125rem(45px) = 115px */
  padding: 0 8px;
  font-size: 0.8em; }

/* PANEL */
.asidePanel {
  background-color: #DDD; }

.asidePanel ul {
  margin: 0;
  list-style: none;
  padding: 0; }

.asidePanel ul li {
  border-bottom: solid 1px #FFF; }

.asidePanel ul li label {
  background: #da0e73;
  color: #FFFFFF;
  height: 2.8125rem;
  line-height: 2.8125rem;
  padding: 0;
  text-align: center;
  font-size: 1rem;
  border-top: 0; }

.asidePanel .bt {
  display: block;
  color: #2d2d2d;
  border-bottom: 0;
  cursor: pointer;
  padding: 0.58rem 0.6667rem;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s; }

/*
	.asidePanel .bt{
		background-color: transparent;
		font-variant: small-caps;
	}*/
.asidePanel a.bt:hover {
  background: #FFF; }

.asidePanel .hasSubMenu > ul {
  background: #DDD; }

.asidePanel .hasSubMenu .subMenuToggle {
  border-left: solid 1px #FFF;
  color: #2d2d2d; }

.asidePanel ul li.m17 label {
  background-color: #6e8438; }

.asidePanel ul li.m17 > .subMenuToggle {
  background-color: #6e8438;
  color: #FFF; }
  .asidePanel ul li.m17 > .subMenuToggle:hover {
    background-color: #8b9d60; }

.asidePanel li.m17.hasSubMenu > ul,
.asidePanel li.m17 .hasSubMenu > ul {
  background-color: #e2e6d7; }

.asidePanel ul li.m18 label {
  background-color: #5b574d; }

.asidePanel ul li.m18 > .subMenuToggle {
  background-color: #5b574d;
  color: #FFF; }
  .asidePanel ul li.m18 > .subMenuToggle:hover {
    background-color: #7c7971; }

.asidePanel li.m18.hasSubMenu > ul,
.asidePanel li.m18 .hasSubMenu > ul {
  background-color: #dedddb; }

.asidePanel ul li.m19 label {
  background-color: #344840; }

.asidePanel ul li.m19 > .subMenuToggle {
  background-color: #344840;
  color: #FFF; }
  .asidePanel ul li.m19 > .subMenuToggle:hover {
    background-color: #5d6d66; }

.asidePanel li.m19.hasSubMenu > ul,
.asidePanel li.m19 .hasSubMenu > ul {
  background-color: #d6dad9; }

.asidePanel ul li.m20 label {
  background-color: #82aa99; }

.asidePanel ul li.m20 > .subMenuToggle {
  background-color: #82aa99;
  color: #FFF; }
  .asidePanel ul li.m20 > .subMenuToggle:hover {
    background-color: #9bbbad; }

.asidePanel li.m20.hasSubMenu > ul,
.asidePanel li.m20 .hasSubMenu > ul {
  background-color: #e6eeeb; }

.asidePanel ul li.m21 label {
  background-color: #e35204; }

.asidePanel ul li.m21 > .subMenuToggle {
  background-color: #e35204;
  color: #FFF; }
  .asidePanel ul li.m21 > .subMenuToggle:hover {
    background-color: #e97536; }

.asidePanel li.m21.hasSubMenu > ul,
.asidePanel li.m21 .hasSubMenu > ul {
  background-color: #f9dccd; }

.main {
  padding: 24px 0;
  font-size: 1em; }

.main .ariane,
.main .titlePage,
.main .content {
  background: #FFF;
  background: rgba(255, 255, 255, 0.7);
  padding: 0 16px; }

.main .ariane {
  text-align: right;
  font-size: 0.8em;
  padding: 8px 16px 0 16px; }

.ariane,
.ariane a {
  color: #999; }

.ariane ul {
  list-style: none;
  display: inline;
  font-size: 1em;
  font-weight: normal;
  margin: 0; }

.ariane ul > li {
  display: inline; }

.ariane ul ul > li:before {
  content: ' > '; }

.ariane ul > li > a,
.ariane ul > li > span {
  padding: 0 3px;
  white-space: nowrap; }

.ariane ul > li > a:hover {
  color: #000;
  text-decoration: underline; }

.main .titlePage {
  padding: 0; }

.main .content {
  padding-bottom: 32px; }

.menuEnfant {
  background: #2d2d2d;
  color: #FFF;
  margin-right: 12px; }

.menuEnfant h2 {
  font-family: 'Open Sans', 'sans-serif';
  margin: 0;
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
  font-size: 1.6em; }

.menuEnfant ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.menuEnfant ul li {
  display: block; }

.menuEnfant .bt {
  color: #FFF;
  display: block;
  padding: 4px 16px; }

.menuEnfant ul li > .bt:hover,
.menuEnfant ul li.select > .bt {
  background-color: rgba(255, 255, 255, 0.15); }

.menuEnfant ul li.select > .bt:hover {
  background-color: rgba(255, 255, 255, 0.3); }

.menuEnfant ul li > a.bt,
.menuEnfant ul li.hasSubMenu > .bt {
  cursor: pointer; }

.menuEnfant ul li ul {
  background-color: rgba(255, 255, 255, 0.15);
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.25s;
  -moz-transition: max-height 0.25s;
  -o-transition: max-height 0.25s;
  transition: max-height 0.25s; }

.menuEnfant ul li.select > ul {
  margin: 2px 0 16px 0;
  padding-bottom: 8px;
  max-height: 600px; }

.menuEnfant ul li li > .bt {
  padding-left: 24px; }

.menuEnfant ul li li > .bt:before {
  background: none repeat scroll 0 0 #ffffff;
  content: "";
  display: block;
  float: left;
  height: 6px;
  margin: 12px 8px 0 0;
  width: 6px; }

.menuEnfant ul li li li > .bt {
  padding-left: 32px; }

.menuEnfant.m17 {
  background-color: #6e8438; }

.menuEnfant .bt {
  color: #FFF; }

.menuEnfant.m18 {
  background-color: #5b574d; }

.menuEnfant .bt {
  color: #FFF; }

.menuEnfant.m19 {
  background-color: #344840; }

.menuEnfant .bt {
  color: #FFF; }

.menuEnfant.m20 {
  background-color: #82aa99; }

.menuEnfant .bt {
  color: #FFF; }

.menuEnfant.m21 {
  background-color: #e35204; }

.menuEnfant .bt {
  color: #FFF; }

.titlePage h1,
.richContent h1,
.richContent h2,
.richContent h3,
.richContent h4,
.richContent h5 {
  text-align: center;
  font-family: 'Open Sans', 'sans-serif'; }

.richContent p,
.richContent ul,
.richContent ol,
.richContent dl {
  font-size: 1em; }

/*			.aside1 .richContent p{
				text-align: justify;
			}*/
.titlePage h1,
.richContent h1 {
  color: #da0e73;
  margin-bottom: 0.5em;
  margin-top: 8px;
  font-weight: normal;
  /*font-size: 1.6em;*/
  font-family: 'jardiland', 'sans-serif';
  font-size: 2em;
  line-height: 1em; }

.richContent h2 {
  font-size: 1.4em;
  font-weight: normal;
  font-style: italic;
  color: #666;
  margin-top: 2em;
  margin-bottom: 1em; }

.richContent h3 {
  font-size: 1.2em;
  font-weight: normal;
  font-style: italic;
  color: #666;
  margin-top: 2em;
  margin-bottom: 1em; }

.richContent h6 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-weight: bold;
  font-style: italic;
  text-align: right; }

.richContent h1:first-child,
.richContent h2:first-child,
.richContent h3:first-child,
.richContent h4:first-child,
.richContent h5:first-child {
  margin-top: 0; }

/*.richContent img,*/
.richContent img.imgRight,
.richContent img.imgLeft {
  margin: 0 auto 10px auto;
  display: block; }

.richContent ul li,
.richContent ul ul > li,
.richContent ul ul ul > li {
  list-style: none; }

.richContent ul > li:before,
.richContent ul ul > li:before,
.richContent ul ul ul > li:before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #da0e73;
  margin: 10px 0 0 -16px;
  -moz-border-radius: 50%;
  border-radius: 50%; }

.richContent ul ul > li:before {
  background-color: #2d2d2d; }

.richContent ul ul ul > li:before {
  background-color: #666; }

.richContent #galerie li:before {
  display: none; }

.richContent .flexMap {
  position: relative;
  max-width: 100%; }
  .richContent .flexMap:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 125%; }
    @media only screen and (min-width: 30.063em) {
      .richContent .flexMap:before {
        padding-bottom: 75%; } }
  .richContent .flexMap iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.richContent a {
  text-decoration: underline; }

a.bouton,
input[type=button],
input[type=submit],
button {
  display: inline-block;
  /*				min-height: 2.3125rem;*/
  border: solid 1px #da0e73;
  background-color: #FFF;
  color: #da0e73;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  overflow: visible;
  /*				padding: 6px 16px;*/
  padding: 6px 16px 7px;
  -webkit-transition: background-color 300ms ease-out;
  -moz-transition: background-color 300ms ease-out;
  -o-transition: background-color 300ms ease-out;
  transition: background-color 300ms ease-out;
  font-family: "Open Sans",sans-serif;
  font-size: 1rem;
  line-height: 1.4em;
  margin: 0;
  position: inherit; }

/*
			.no-touch a.bouton,
			.no-touch .richContent input[type=button],
			.no-touch .richContent input[type=submit],
			.no-touch .richContent button{
				padding: 4px 8px;
			}
			*/
a.bouton:hover,
input[type=button]:hover,
input[type=submit]:hover,
button:hover {
  background-color: #da0e73;
  color: #FFF; }

a.bouton:focus,
input[type=button]:focus,
input[type=submit]:focus,
button:focus {
  background-color: #da0e73;
  color: #FFF; }

.footer {
  background-color: #2d2d2d;
  padding: 8px 0; }

.footerAddress {
  text-align: center; }

.footerAddress p,
.footerAddress a {
  color: #FFF;
  font-size: 1em;
  font-weight: 600;
  margin: 0;
  line-height: 1.2em; }

.footerAddress .name {
  display: block;
  background-image: url("../images/map-footer.png");
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 90px;
  border-bottom: solid 2px #FFF;
  text-indent: -999px; }

.footerAddress .adress {
  display: block;
  font-size: 0.75em; }

.footerAddress .city {
  display: block;
  font-size: 1.2em; }

.footerAddress .tel {
  display: block;
  font-size: 1.2em;
  line-height: 2em; }

.footerMenu {
  text-align: center;
  padding: 4px 0; }

.footerMenu ul, .footerMenu .cgv {
  list-style: none;
  color: #EEE;
  display: inline;
  font-size: 0.8em;
  font-weight: 600;
  margin: 0; }

.footerMenu ul > li {
  display: inline; }

.footerMenu ul > li > a, .footerMenu .cgv > a {
  color: #DDD;
  padding: 0 4px;
  white-space: nowrap; }

.footerMenu ul > li > a:hover, .footerMenu .cgv > a:hover {
  color: #FFF;
  text-decoration: underline; }

.footerMenu > ul > li:after, .footerMenu .cgv:after {
  content: '-'; }

.pointsys {
  display: inline-block;
  width: 59px;
  height: 20px;
  text-indent: -9999px;
  text-align: left;
  outline: none;
  background: url("../images/pointsys.png") 0 0 no-repeat;
  margin: 0;
  padding: 0;
  vertical-align: bottom; }

/* Tablette / PC */
@media only screen and (min-width: 60.063em), only screen and (min-width: 30.063em) and (max-width: 40em) {
  .footerAddress {
    text-align: left; }
  .footerAddress p {
    display: block;
    width: 464px;
    margin: 0 auto; }
  .footerAddress .name {
    float: left;
    width: 210px;
    height: 90px;
    border-bottom: 0;
    border-right: solid 2px #FFF;
    text-indent: -999px;
    margin-right: 16px; }
  .footerAddress .adress {
    padding-top: 8px; } }

/* Mobile Paysage */
@media only screen and (min-width: 30.063em) {
  .tab-bar .menuRecherche {
    width: 332px;
    /* 277px+2.8125rem(45px) = 332px */ }
  .tab-bar .menuRecherche input[type=text] {
    width: 240px; } }

/* Tablette / PC */
@media only screen and (min-width: 40.063em) {
  .header .bt_accueil {
    width: 320px;
    margin: 9px 0 9px 22px; }
  /*////////////////////////////////////////////////////////////////////////////*/
  /*///////////////////////////////// RECHERCHE ////////////////////////////////*/
  /*////////////////////////////////////////////////////////////////////////////*/
  .header .btContact {
    width: 120px;
    height: 82px;
    display: block;
    float: left;
    margin-left: 84px;
    background-image: url("../images/bt_contact.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    text-indent: -9999px;
    outline: none; }
  .header .menuRecherche {
    float: right;
    height: 40px;
    /*padding-right: 32px;*/
    padding: 4px 0; }
  .header .menuSocial {
    display: block;
    clear: right;
    float: right;
    padding: 4px 0; }
  .header .menuSocial ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .header .menuSocial ul > li > a {
    display: block;
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    background-image: url("../images/socialIcon_32.png");
    background-repeat: no-repeat;
    -webkit-transition: background-color 0.25s;
    -moz-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s; }
  .header .menuSocial ul > li > a:hover {
    background-color: #FFF; }
  .header .menuSocial ul > li > a.btFacebook {
    background-position: 0px 0px; }
  .header .menuSocial ul > li > a.btFacebook:hover {
    background-position: 0px -64px; }
  .header .menuAssistance {
    float: right;
    padding: 4px 0; }
  .menuAssistance-tel {
    font-weight: bold;
    line-height: 32px;
    margin-right: 64px; }
    .menuAssistance-tel:before {
      content: '';
      display: block;
      float: left;
      width: 32px;
      height: 32px;
      margin-right: 4px;
      background-image: url("../images/assistance.png");
      background-position: 0 0;
      background-repeat: no-repeat; } }
    @media only screen and (min-width: 40.063em) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (min--moz-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (-o-min-device-pixel-ratio: 1.5 / 1), only screen and (min-width: 40.063em) and (min-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (min-resolution: 144dpi), only screen and (min-width: 40.063em) and (min-resolution: 1.5dppx) {
      .menuAssistance-tel:before {
        background-image: url("../images/assistance@2x.png");
        -webkit-background-size: 32px 32px;
        -moz-background-size: 32px 32px;
        -o-background-size: 32px 32px;
        background-size: 32px 32px; } }

@media only screen and (min-width: 40.063em) {
  .topMenu ul,
  .topMenu li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .topMenu ul.menu {
    float: right; }
  .topMenu .bt {
    display: block;
    background-color: #2d2d2d;
    color: #FFF;
    font-size: 0.9em;
    padding: 0 16px;
    font-weight: 600;
    text-align: center; }
  .topMenu ul.menu > li {
    display: block;
    float: left;
    margin: 0 2px;
    position: relative; }
  .topMenu ul.menu > li > .bt {
    height: 32px;
    line-height: 32px;
    padding: 0 16px; }
  .topMenu ul.menu > li:hover > .bt,
  .topMenu ul.menu > li > .bt:hover {
    background-color: #575757; }
  .topMenu ul.menu > li > ul {
    /*display: none;*/
    display: block;
    position: absolute;
    width: 100%;
    z-index: 10;
    max-height: 0;
    overflow: hidden;
    background-color: #2d2d2d;
    -webkit-transition: max-height 0.25s, background-color 0.25s;
    -moz-transition: max-height 0.25s, background-color 0.25s;
    -o-transition: max-height 0.25s, background-color 0.25s;
    transition: max-height 0.25s, background-color 0.25s; }
  .topMenu ul.menu > li:hover > ul {
    max-height: 200px;
    background-color: #575757; }
  .topMenu ul.menu > li > ul > li > .bt {
    padding: 4px 16px;
    line-height: 1.2em;
    background-color: transparent;
    font-size: 0.85em;
    font-weight: normal; }
  .topMenu ul.menu > li > ul > li > .bt:hover {
    background-color: rgba(255, 255, 255, 0.15); }
  .mainMenu {
    background-color: #FFF; }
  .mainMenuOverlay {
    display: none;
    background: transparent;
    position: absolute;
    z-index: 1;
    -webkit-transition: background-color 0.25s;
    -moz-transition: background-color 0.25s;
    -o-transition: background-color 0.25s;
    transition: background-color 0.25s; }
  .mainMenuOverlay.active {
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.4);
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    transition: background-color 0.5s; }
  .mainMenuList {
    position: relative;
    z-index: 1; }
  .mainMenuList,
  .mainMenuList ul,
  .mainMenuList li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .mainMenuList > li > .bt {
    display: block;
    float: left;
    width: 5%;
    height: 45px;
    background-image: url("../images/bt-home.png");
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    outline: none; } }
  @media only screen and (min-width: 40.063em) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (min--moz-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (-o-min-device-pixel-ratio: 1.5 / 1), only screen and (min-width: 40.063em) and (min-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (min-resolution: 144dpi), only screen and (min-width: 40.063em) and (min-resolution: 1.5dppx) {
    .mainMenuList > li > .bt {
      background-image: url("../images/bt-home@2x.png");
      -webkit-background-size: 24px 23px;
      -moz-background-size: 24px 23px;
      -o-background-size: 24px 23px;
      background-size: 24px 23px; } }

@media only screen and (min-width: 40.063em) {
  .mainMenuList > li > .bt:hover {
    background-color: #EEE; }
  .mainMenuList ul.menu {
    display: block;
    float: left;
    width: 95%;
    position: relative; }
  .mainMenuList ul > li > ul > .hasSubMenu {
    position: relative; }
  .mainMenuList .hasSubMenu > .subMenuToggle {
    position: absolute;
    padding: 0;
    margin: 0;
    font-size: 1.4em;
    background-color: transparent;
    height: 45px;
    line-height: 2.5125rem;
    text-align: center;
    border: none;
    color: #CCC;
    right: 2px;
    -webkit-transition: background-color 0.25s, width 0.25s;
    -moz-transition: background-color 0.25s, width 0.25s;
    -o-transition: background-color 0.25s, width 0.25s;
    transition: background-color 0.25s, width 0.25s;
    width: 0rem;
    overflow: hidden; }
  .mainMenuList .hasSubMenu > .subMenuToggle:before {
    content: '»'; }
  .mainMenuList ul.menu > li {
    float: left;
    display: block;
    width: 20%; }
  .mainMenuList ul.menu > li > .bt {
    display: block;
    height: 45px;
    line-height: 45px;
    padding: 0 0.25em;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2d2d2d;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    transition: background-color 0.5s; } }
  @media only screen and (min-width: 40.063em) and (min-width: 60.063em) {
    .mainMenuList ul.menu > li > .bt {
      font-size: 1em; } }

@media only screen and (min-width: 40.063em) {
  .mainMenuList ul.menu > li.select > .bt,
  .mainMenuList ul.menu > li > .bt:hover {
    background-color: #2d2d2d;
    color: #FFF; }
  .mainMenuList ul.menu > li.select > .bt:before,
  .mainMenuList ul.menu > li > .bt:hover:before {
    background-position: -6px -4px !important; }
  .mainMenuList ul.menu > li.m228 > .bt {
    color: #da0e73; }
  .mainMenuList ul.menu > li.m228.select > .bt,
  .mainMenuList ul.menu > li.m228 > .bt:hover {
    color: #FFF;
    background-color: #da0e73; }
  .mainMenuList ul.menu > li > ul {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 8;
    max-height: 0;
    overflow: hidden;
    background-color: #2d2d2d;
    -webkit-transition: max-height 0.25s;
    -moz-transition: max-height 0.25s;
    -o-transition: max-height 0.25s;
    transition: max-height 0.25s; }
  .mainMenuList ul.menu > li.select > ul {
    padding: 1px;
    max-height: 300px;
    z-index: 9;
    -webkit-transition: max-height 0.5s, background-color 0.5s;
    -moz-transition: max-height 0.5s, background-color 0.5s;
    -o-transition: max-height 0.5s, background-color 0.5s;
    transition: max-height 0.5s, background-color 0.5s; }
  .mainMenuList ul.menu > li ul > li {
    display: block;
    float: left;
    width: 33.3333%;
    display: block;
    padding: 2px 0; } }
  @media only screen and (min-width: 40.063em) and (min-width: 60.063em) {
    .mainMenuList ul.menu > li ul > li {
      width: 25%; } }

@media only screen and (min-width: 40.063em) {
  .mainMenuList ul.menu > li ul > li.hidden {
    display: none !important; }
  .mainMenuList ul.menu > li ul > li > .bt {
    display: block;
    cursor: pointer;
    margin: 0 2px;
    line-height: 1.3em;
    font-size: 0.9em;
    padding: 4px 12px;
    height: 45px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    color: #FFF;
    background-color: transparent;
    -webkit-transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
    -moz-transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
    -o-transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
    transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
    position: relative; }
  .no-flexbox .mainMenuList ul.menu > li ul > li > .bt {
    display: block; }
  .mainMenuList ul.menu > li ul > li:hover > .bt {
    color: #333;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: background-color 0.5s, margin-right 0.5s, padding 0.5s;
    -moz-transition: background-color 0.5s, margin-right 0.5s, padding 0.5s;
    -o-transition: background-color 0.5s, margin-right 0.5s, padding 0.5s;
    transition: background-color 0.5s, margin-right 0.5s, padding 0.5s; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu > .bt {
    padding: 4px 34px; }
  .touch .mainMenuList ul.menu > li ul > li.hasSubMenu > .bt,
  .mainMenuList ul.menu > li ul > li.hasSubMenu:hover > .bt {
    margin-right: 47px;
    padding: 4px 12px; }
  .touch .mainMenuList ul.menu > li ul > li > .bt {
    border: solid 1px rgba(255, 255, 255, 0.25); }
  .touch .mainMenuList ul.menu > li ul > li > .subMenuToggle {
    width: 45px;
    background-color: rgba(255, 255, 255, 0.25); }
  .mainMenuList ul.menu > li ul > li:hover > .subMenuToggle {
    color: #333;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: background-color 0.5s, width 0.5s;
    -moz-transition: background-color 0.5s, width 0.5s;
    -o-transition: background-color 0.5s, width 0.5s;
    transition: background-color 0.5s, width 0.5s;
    width: 45px; }
  .mainMenuList ul.menu > li ul > li > .subMenuToggle:hover,
  .mainMenuList ul.menu > li ul > li > a.bt:hover {
    color: #333;
    background-color: #FFF; }
  .mainMenuList ul.menu > li ul > li:hover > .subMenuToggle {
    border-left: solid 1px rgba(255, 255, 255, 0.25); }
  .mainMenuList ul.menu > li ul > li > .bt > span {
    width: 100%;
    overflow: hidden; }
  /*///////////////////////////// NIVEAU 3 /////////////////////////////*/
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select {
    width: 100%; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select > .subMenuToggle,
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select > .bt {
    display: none; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu > ul {
    display: none;
    width: 66.6667%;
    float: right;
    background: rgba(255, 255, 255, 0.25); } }
  @media only screen and (min-width: 40.063em) and (min-width: 60.063em) {
    .mainMenuList ul.menu > li ul > li.hasSubMenu > ul {
      width: 75%; } }

@media only screen and (min-width: 40.063em) {
  .mainMenuList ul.menu > li ul > li.hasSubMenu > ul.hasOpenSubMenu {
    width: 100%; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu > ul.hasOpenSubMenu > li {
    padding: 0; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul {
    display: block; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li {
    width: 50%; } }
  @media only screen and (min-width: 40.063em) and (min-width: 60.063em) {
    .mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li {
      width: 33.3333%; } }

@media only screen and (min-width: 40.063em) {
  .mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li.subMenuLabel {
    position: absolute;
    left: 0;
    top: 2px;
    bottom: 2px;
    height: auto;
    width: 33.3333%;
    margin: 0;
    background: transparent;
    border-right: solid 2px #FFF;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center; } }
  @media only screen and (min-width: 40.063em) and (min-width: 60.063em) {
    .mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li.subMenuLabel {
      width: 25%; } }

@media only screen and (min-width: 40.063em) {
  .mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li.subMenuLabel > label {
    color: #FFF;
    cursor: inherit;
    display: block;
    width: 100%;
    padding-left: 53px;
    padding-right: 8px;
    font-size: 1.1em;
    font-weight: 600;
    line-height: 1.3em;
    text-align: center;
    text-transform: uppercase; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: 10;
    height: 100%;
    width: 45px; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back > a {
    line-height: 1.3em;
    padding: 0 0 8px 0;
    width: 100%;
    height: 100%;
    color: #DDD;
    font-size: 2em;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-transition: background-color 0.5s, width 0.5s;
    -moz-transition: background-color 0.5s, width 0.5s;
    -o-transition: background-color 0.5s, width 0.5s;
    transition: background-color 0.5s, width 0.5s; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back > a:before {
    content: '«';
    text-align: center;
    width: 100%; }
  .mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back > a:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: #FFF; }
  /* HD/"retina"
-----------------------------------------------------------------------------------------------------------*/ }

@media only screen and (min-width: 40.063em) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (min--moz-device-pixel-ratio: 1.5), only screen and (min-width: 40.063em) and (min-resolution: 240dpi) {
  .asidePanel li.m17 > .bt:before,
  .asidePanel li.m18 > .bt:before,
  .asidePanel li.m19 > .bt:before,
  .asidePanel li.m20 > .bt:before,
  .asidePanel li.m21 > .bt:before,
  .mainMenuList ul.menu > li > .bt:before {
    background-image: url("../images/menu-x2.png");
    -moz-background-size: 32px 192px;
    -o-background-size: 32px 192px;
    -webkit-background-size: 32px 192px;
    background-size: 32px 192px; } }

@media only screen and (min-width: 40.063em) {
  .main .aside1,
  .main .aside2 {
    float: left; }
  .main .ariane,
  .main .titlePage,
  .main .content {
    padding-left: 48px;
    padding-right: 48px;
    float: right; }
  /*	.richContent{
		min-height: 400px;
	}*/
  .titlePage h1,
  .richContent h1,
  .richContent h2,
  .richContent h3,
  .richContent h4,
  .richContent h5 {
    text-align: left; }
  .richContent h2 {
    border-bottom: solid 2px #da0e73; }
  .richContent h6 {
    text-align: center;
    margin-left: 50%; }
  .richContent img {
    display: inline; }
  .richContent img.imgRight {
    float: right;
    margin: 0 0 10px 20px; }
  .richContent img.imgLeft {
    float: left;
    margin: 0 20px 10px 0; }
  .footer > .row {
    position: relative; }
  .footerMenu {
    text-align: right;
    position: initial; }
  .footerMenu .cgv:after {
    content: ''; }
  .pointsys {
    display: block;
    position: absolute;
    right: 0;
    bottom: 8px; } }

@media only screen and (min-width: 60.063em) {
  .topMenu {
    margin-top: -32px; }
  .menuRecherche input[type="text"] {
    width: 285px; } }

/* HD/"retina"
-----------------------------------------------------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-resolution: 240dpi) {
  .banner .panier-menu > label:before {
    background-image: url("../images/icon-panier@2x.png");
    -moz-background-size: 24px 24px;
    -o-background-size: 24px 24px;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px; }
  .banner .secu-menu > label:before {
    background-image: url("../images/icon-secu2@2x.png");
    -moz-background-size: 24px 24px;
    -o-background-size: 24px 24px;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px; }
  .banner .secu-menu > ul > li.logout .bt:before {
    background-image: url("../images/icon-logout@2x.png");
    -moz-background-size: 24px 24px;
    -o-background-size: 24px 24px;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px; }
  .tab-bar .secu-icon span {
    background-image: url("../images/icon-secu@2x.png");
    -moz-background-size: 32px 32px;
    -o-background-size: 32px 32px;
    -webkit-background-size: 32px 32px;
    background-size: 32px 32px; }
  .header .bt_accueil {
    background-image: url("../images/le-comptoir-du-jardinier@2x.png");
    -moz-background-size: 305px 194px;
    -o-background-size: 305px 194px;
    -webkit-background-size: 305px 194px;
    background-size: 305px 194px; }
  .header .btContact {
    background-image: url("../images/bt_contact-x2.png");
    -moz-background-size: 120px 82px;
    -o-background-size: 120px 82px;
    -webkit-background-size: 120px 82px;
    background-size: 120px 82px; }
  .menuRecherche input[type=submit] {
    background-image: url("../images/bt_search-x2.png");
    -moz-background-size: 24px 24px;
    -o-background-size: 24px 24px;
    -webkit-background-size: 24px 24px;
    background-size: 24px 24px; }
  .footerAddress .name {
    background-image: url("../images/map-footer@2x.png");
    -moz-background-size: 208px 90px;
    -o-background-size: 208px 90px;
    -webkit-background-size: 208px 90px;
    background-size: 208px 90px; }
  .footer .pointsys {
    background-image: url("../images/pointsys-x2.png");
    -moz-background-size: 59px 20px;
    -o-background-size: 59px 20px;
    -webkit-background-size: 59px 20px;
    background-size: 59px 20px; } }
